import React from 'react';
import { Route } from '../../models/route.model';
import './route-selector.component.scss';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import LoadingOverlayComponent from '../loading-overlay/loading-overlay.component';

class RouteSelector extends React.Component<{routes: Route[], selectedRoute: number, setSelectedRoute: any}, {}> {

    selectNextRoute(dir: number) {
        if (dir === -1) {
            if (this.props.selectedRoute === 0) {
                this.props.setSelectedRoute(this.props.routes.length - 1);
            } else {
                this.props.setSelectedRoute(this.props.selectedRoute - 1);
            }
        } else {
            if (this.props.selectedRoute >= this.props.routes.length - 1) {
                this.props.setSelectedRoute(0);
            } else {
                this.props.setSelectedRoute(this.props.selectedRoute + 1);
            }
        }
    }

    render() {
        if (!this.props.routes.length) {
            return (
                <LoadingOverlayComponent inline={true} height={'34px'} size={'xs'} />
            );
        } else {
            const route = this.props.routes[this.props.selectedRoute];
            let disabled = null;
            if (this.props.routes.length === 1) {
                disabled = 'disabled';
            }
            return (
                <div className={'route-selector-container'}>
                    <div className={'arrow left ' + disabled} onClick={() => {this.selectNextRoute(-1)}}>
                        <ChevronLeft />
                    </div>
                    <div className={'selected-route'}>
                        <div className={'title'}>
                            {route.title} ({this.props.selectedRoute + 1}/{this.props.routes.length})
                        </div>
                    </div>
                    <div className={'arrow right ' + disabled} onClick={() => {this.selectNextRoute(1)}}>
                        <ChevronRight />
                    </div>
                </div>
            );
        }
    }
}

export default RouteSelector;
