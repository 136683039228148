import React, { Component } from 'react';
import './brand.component.scss';
import { Link } from 'react-router-dom';

class Brand extends Component {
    render() {
        return (
            <Link to="/" className={'brand'}>
                <div className={'brand-icon'}>
                    <span className={'lc-icon-Car'} />
                    <span className={'title'}>Jizda<span className={'middle'}>Vedle</span>Tebe</span>
                </div>
            </Link>
        );
    }
}

export default Brand;
