import React from 'react';
import './login.scss';

import Brand from '../../components/brand/brand.component';
import authService from '../../services/auth.service';
import { Navigate } from 'react-router-dom';
import LoadingOverlayComponent from '../../components/loading-overlay/loading-overlay.component';
import { TextField } from '@material-ui/core';
import CryptoService from "../../services/crypto.service";

class Login extends React.Component<{}, {form: string, password: string, email: string, loading: boolean, loginError: boolean, remember: any}> {
    redirect = false;
    constructor(props: any) {
        super(props);
        let remember = localStorage.getItem('remember') as any;
        let email = '';
        let password = '';
        let autoLogin = false;
        console.log(remember);
        if (remember) {
            remember = JSON.parse(remember);
            if (remember && remember.email) {
                email = CryptoService.decrypt('salt', remember.email);
                password = CryptoService.decrypt('salt', remember.password);
                autoLogin = true;
            }
        }

        this.state = {
            form: 'login',
            email: email,
            password: password,
            loading: false,
            loginError: false,
            remember : true,
        };

        if (autoLogin) {
            this.signIn(null).then();
        }

    }

    displayForgotForm = (event: any) => {
        this.setState({form: 'forgotten-password', loginError: false});
    }

    displayLoginForm = (event: any) => {
        this.setState({form: 'login', loginError: false});
    }

    signIn = async (event: any) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({loading: true, loginError: false});
        const logged = await authService.login(this.state.email, this.state.password);
        if (logged) {
            if (this.state.remember) {
                const email = CryptoService.encrypt('salt', this.state.email);
                const password = CryptoService.encrypt('salt', this.state.password);
                localStorage.setItem('remember', JSON.stringify({email, password}));
            }
            this.redirect = true;
            this.setState({loading: false});
        } else {
            this.setState({loading: false, loginError: true});
        }
    }

    handleInputChange = (e: any) => {
        const { name, value } = e.target;
        const realValue = e.target.checked ? e.target.checked : value;
        // @ts-ignore
        this.setState({ [name]: realValue });
    };

    render() {
        if (this.redirect) {
            return ( <Navigate to="/" replace={true} />);
        }
        let template;
        let loadingOverlay = null;
        if (this.state.loading) {
            loadingOverlay = (<LoadingOverlayComponent />);
        }
        let loginError = null;
        if (this.state.loginError) {
            loginError = (<div className={'text-center'}><div className={'login-error'}>Wrong email or pin</div></div>);
        }

        if (this.state.form === 'login') {
            template = (<>
                <div className={'form-line'}>
                    <TextField className={'w-100'} name={'email'} label="Email" type={'email'} variant="outlined"
                               value={this.state.email} onChange={this.handleInputChange}
                    />
                </div>
                <div className={'form-line'}>
                    <TextField type={'password'} name={'password'} className={'w-100'} label="Pin" variant="outlined"
                               value={this.state.password} onChange={this.handleInputChange}
                    />
                </div>
                    { this.state.remember }
                <div className={'form-line'}>
                    <div className="form-check">
                        <input value={this.state.remember} type={'checkbox'} onChange={this.handleInputChange} id={'remember'} name={'remember'} />
                        <label className="form-check-label remember-l" htmlFor={'remember'}>Remember me!</label>
                    </div>
                </div>
                {loginError}
                <div className={'form-line text-center'}>
                    <a onClick={this.displayForgotForm}>Forgot password?</a>
                </div>
                <button type={'submit'} className={'btn btn-primary btn-lg btn-block'} onClick={this.signIn}>Sign in</button></>
            );
        } else if (this.state.form === 'forgotten-password') {
            template = (<>
                    <div className={'form-line'}>
                        <h2>You moron...</h2>
                    </div>
                    <div className={'form-line'}>
                        <TextField id="outlined-basic" className={'w-100'} name={'email'} label="Email" variant="outlined"
                                   value={this.state.email} onChange={this.handleInputChange}
                        />
                    </div>
                    <div className={'form-line text-center'}>
                        <a onClick={this.displayLoginForm}>Back to login</a>
                    </div>
                    <button type={'submit'} className={'btn btn-primary btn-lg btn-block'}>Get new password</button>
                </>
            );
        }
        return (
            <div className={'login-container'}>
                <div className={'content'}>
                    {loadingOverlay}
                    <div className={'text-center'}>
                        <span className={'d-inline-block'}>
                            <Brand />
                        </span>
                    </div>
                    <div className={'mt-5'}>
                        <form onSubmit={this.signIn}>
                            {template}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
