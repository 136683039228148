import apiService from "./api.service";
import { User } from '../models/user.model';
import { SERVER_LOCATION } from '../utils/config.util';

export const TOKEN_STORAGE = 'JIZDNICEK_TOKEN';
class AuthService {
    isAuthenticated = false;
    user: User = {
        id : -1
    };

    setToken(token: string) {
        localStorage.setItem(TOKEN_STORAGE, token);
    }

    async login(email: string, password: string) {
        const body = {email: email, password: password};
        const response = await apiService.post(SERVER_LOCATION + '/api/auth/login', body);
        if (response.token) {
            this.isAuthenticated = true;
            this.setToken(response.token);
            await this.isLoggedIn();
            return true;
        } else {
            return false;
        }
    }

    async isLoggedIn(): Promise<boolean> {
        const response = await apiService.get(SERVER_LOCATION + '/api/auth/me');
        if (response.error) {
            this.isAuthenticated = false;
            return false;
        } else {
            this.isAuthenticated = true;
            this.user = response;
            return true;
        }
    }

    refreshToken() {

    }

    async logout() {
        localStorage.removeItem('remember');
        await apiService.post(SERVER_LOCATION + '/api/auth/logout', {});
        localStorage.removeItem(TOKEN_STORAGE);
        this.isAuthenticated = false;
    }
}
const authService = new AuthService();
export default authService;

