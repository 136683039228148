import React from 'react';
import { CloudUpload } from '@material-ui/icons';
import { TextField } from '@material-ui/core';
import './my-account.scss';
import apiService from '../../services/api.service';
import LoadingOverlayComponent from '../../components/loading-overlay/loading-overlay.component';
import { toast } from 'react-toastify';
import { Profile } from '../../models/profile.model';
import { PasswordUpdate } from '../../models/password-update.model';
import { SERVER_LOCATION } from '../../utils/config.util';

class MyAccount extends React.Component<{}, {user: Profile, password: PasswordUpdate, loading: boolean, uploadingAvatar: boolean}> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            uploadingAvatar: false,
            user: {id: -1},
            password: {},
        };
    }

    componentDidMount() {
        this.getMySettings().then();
    }

    async getMySettings() {
        this.setState({loading: true});
        const response = await apiService.get(SERVER_LOCATION + '/api/auth/me');
        this.setState({user: response, loading: false});
    }

    uploadAvatar = async (e: any) => {
        this.setState({uploadingAvatar: true});
        const file = e.nativeEvent.target.files[0];
        const formData: FormData = new FormData();
        formData.append('uploadFile[]', file);

        const response = await apiService.uploadPost(SERVER_LOCATION + '/api/images/upload/', formData);

        this.state.user.logo = response.savedImages[0].url;
        this.state.user.logo_new = {path: response.savedImages[0].uuid, uuid: response.savedImages[0].uuid};
        this.setState({uploadingAvatar: false});
    };


    handleUserInputChange = (e: any) => {
        const { name, value } = e.target;
        // @ts-ignore
        // eslint-disable-next-line
        this.state.user[name] = value
        this.setState({});
    };

    handlePasswordInputChange = (e: any) => {
        const { name, value } = e.target;
        // @ts-ignore
        // eslint-disable-next-line
        this.state.password[name] = value
        this.setState({});
    };

    async save() {
        this.setState({loading: true});
        const response = await apiService.put(SERVER_LOCATION + '/api/auth/me', this.state.user);
        let passwordResponse = null;
        if (this.state.password.newPassword) {
            passwordResponse = await apiService.post(SERVER_LOCATION + '/api/user/change-password', {
                    userId: this.state.user.id,
                    current_password: this.state.password.currentPassword,
                    new_password: this.state.password.newPassword,
                    new_password_confirmation: this.state.password.passwordRepeat
                });
        }
        if (response.status === 'ok' && (!passwordResponse || (passwordResponse && passwordResponse.status === 'ok'))) {
            toast.success("Profile updated!", {
                autoClose: 2000,
            })

            this.state.password.currentPassword = '';
            this.state.password.newPassword = '';
            this.state.password.passwordRepeat = '';
            await this.getMySettings();
        } else {
            toast.warning("Something went wrong!", {
                autoClose: 2000,
            })
            this.setState({loading: false});
        }
    }

    render() {
        if (this.state.loading && this.state.user.id === -1) {
            return (<LoadingOverlayComponent inline={true} />)
        }
        let uploadingAvatar = null;
        if (this.state.uploadingAvatar) {
            uploadingAvatar = (<LoadingOverlayComponent />);
        }
        let loading = null
        if (this.state.loading) {
            loading = (<LoadingOverlayComponent />)
        }

        return (
            <div className={'my-account position-relative'}>
                {loading}
                <div className={'text-center'}>
                    <div className={'avatar'} style={{backgroundImage: this.state.user.logo ? 'url(' + this.state.user.logo + ')' : undefined}}>
                        {uploadingAvatar}
                        <input type={'file'} className={'invisible-input'} onChange={this.uploadAvatar.bind(this)} accept='.png,.jpg,.gif' />
                        <CloudUpload />
                    </div>
                </div>
                <div className={'form-group'}>
                    <TextField id="forename" className={'w-100'} name={'forename'} label="Name" type={'text'} variant="outlined"
                               value={this.state.user.forename} onChange={this.handleUserInputChange}
                    />
                </div>
                <div className={'form-group'}>
                    <TextField id="surname" className={'w-100'} name={'surname'} label="Surname" type={'text'} variant="outlined"
                               value={this.state.user.surname} onChange={this.handleUserInputChange}
                    />
                </div>
                <h4>Bank account</h4>
                <div className={'form-group'}>
                    <div className={'row condensed-spaces'}>
                        <div className={'col col-4'}>
                            <TextField id="bank_prefix" inputProps={{ maxLength: 6 }} placeholder={'000000'} className={'w-100'} name={'bank_prefix'} label="Prefix" type={'text'} variant="outlined"
                                       value={this.state.user.bank_prefix} onChange={this.handleUserInputChange}
                            />
                        </div>
                        <div className={'col col-5'}>
                            <TextField id="bank_account" inputProps={{ maxLength: 10 }} placeholder={'0000000000'} className={'w-100'} name={'bank_account'} label="Number" type={'text'} variant="outlined"
                                       value={this.state.user.bank_account} onChange={this.handleUserInputChange}
                            />
                        </div>
                        <div className={'col col-3'}>
                            <TextField id="bank_code" inputProps={{ maxLength: 4 }} placeholder={'0000'} className={'w-100'} name={'bank_code'} label="Code" type={'text'} variant="outlined"
                                       value={this.state.user.bank_code} onChange={this.handleUserInputChange}
                            />
                        </div>
                    </div>

                </div>
                <h4>Change password</h4>
                <div className={'form-group'}>
                    <TextField id="currentPassword" className={'w-100'} name={'currentPassword'} label="Current password" type={'password'} variant="outlined"
                               value={this.state.password.currentPassword} onChange={this.handlePasswordInputChange}
                    />
                </div>
                <div className={'form-group'}>
                    <TextField id="newPassword" className={'w-100'} name={'newPassword'} label="New password" type={'password'} variant="outlined"
                               value={this.state.password.newPassword} onChange={this.handlePasswordInputChange}
                    />
                </div>
                <div className={'form-group'}>
                    <TextField id="passwordRepeat" className={'w-100'} name={'passwordRepeat'} label="Repeat password" type={'password'} variant="outlined"
                               value={this.state.password.passwordRepeat} onChange={this.handlePasswordInputChange}
                    />
                </div>
                <button className={'btn btn-primary btn-lg btn-block'} onClick={this.save.bind(this)}><span className={'lc-icon-Floppy'}/> Save profile</button>
            </div>
        );
    }
}

export default MyAccount;
