import React from 'react';
import './payments.component.scss';
import LoadingOverlayComponent from '../loading-overlay/loading-overlay.component';
import RemainingTime from '../remaining-time/remaining-time.component';
import NoItemFound from '../no-item-found/no-item-found.component';
import DateService from '../../services/date.service';
import { Payment } from '../../models/payment.model';
import RouteService from '../../services/routes.service';
import { toast } from 'react-toastify';
class Payments extends React.Component<
    {payments: Payment[], loading: boolean, type: string, reloadPayments: any},
    {qrCode: string, qrCodeId: number, qrCodeUser: string, qrVisible: boolean, qrPaymentInfo: any}> {
    routeService;

    constructor(props:any) {
        super(props);
        this.state = {
            qrCode: '',
            qrCodeId: 0,
            qrCodeUser: '',
            qrVisible: false,
            qrPaymentInfo: {},
        };
        this.routeService = new RouteService();
        this.updateTimeClock();
    }

    updateTimeClock() {
        setInterval(() => {
            for (const payment of this.props.payments) {
                const today = new Date().getTime();
                let timeLimit = new Date(payment.created_at).getTime() + (5 * 24 * 60 * 60 * 1000);
                const timeRemaining = Math.round((timeLimit - today) / 1000);
                payment.remainingTime = timeRemaining;
            }
            this.setState({});
        }, 1000)
    }

    async setAsReceived(payment: Payment) {
        payment.loading = true;
        if (window.confirm('Are you sure you want to set payment as received? This can\'t be review later')) {
            this.setState({});
            const response = await this.routeService.setPaymentReceived(payment.id);
            if (response.status === 'ok') {
                toast.success("Payment set as received!", {
                    autoClose: 2000,
                });
            }
            this.props.reloadPayments();
        }
    }

    async setAsPaid(payment: Payment) {
        // payment.loading = true;
        this.setState({});
    }

    generateQrCode(payment: Payment) {
        const user = payment.user_to;
        const accountNumber = user.profile?.bank_account;
        const accountPrefix = user.profile?.bank_prefix ? 'accountPrefix=' + user.profile?.bank_prefix + '&' : '';
        const bankCode = user.profile?.bank_code;
        if (!accountNumber || !bankCode) {
            alert('User didn\'t set bank information!');
            return;
        }
        const amount = payment.amount;
        const vsSymbol = payment.id;
        const ksSymbol = payment.route_id;
        const message = 'Payment for shared ride.';
        const qrCode = 'https://api.paylibo.com/paylibo/generator/czech/image?' + accountPrefix + 'accountNumber=' + accountNumber + '&bankCode=' + bankCode + '&amount=' + amount +
            '&currency=CZK&vs=' + vsSymbol + '&ks=' + ksSymbol + '&message=' + message;
        this.setState({qrCode, qrCodeId: payment.id, qrCodeUser: user.full_name!, qrVisible: true, qrPaymentInfo: {
                bankAccount: accountNumber,
                accountPrefix: user.profile?.bank_prefix,
                bankCode: bankCode,
                vsSymbol,
                ksSymbol
            }});
    }

    render() {
        const payments = [];
        if (this.props.loading) {
            return (
                <LoadingOverlayComponent inline={true} />
            );
        }

        if (!this.props.payments.length) {
            return (<NoItemFound />);
        }

        for (const payment of this.props.payments) {
            let extraTypeClass = '';
            let user = '';
            let button;
            if (this.props.type === 'income') {
                extraTypeClass = 'income';
                user = payment.user.full_name!;
                button = (
                    <div className={'btn-group'}>
                        <button className={'btn btn-primary btn-sm'} onClick={() => {this.setAsReceived(payment)}}>&nbsp;<span className={'lc-icon-Check'}></span>&nbsp;</button>
                        <button className={'btn btn-secondary btn-sm'} onClick={() => {this.generateQrCode(payment)}}>&nbsp;<span className={'lc-icon-QR'}></span>&nbsp;</button>
                    </div>
                );
            } else if (this.props.type === 'outcome') {
                extraTypeClass = 'outcome';
                user = payment.user_to.full_name!;
                button = (
                    <div className={'btn-group'}>
                        <button className={'btn btn-secondary btn-sm'} onClick={() => {this.generateQrCode(payment)}}>&nbsp;<span className={'lc-icon-QR'}></span>&nbsp;</button>
                    </div>
                );
            }
            let loadingOverlay = null;
            if (payment.loading) {
                loadingOverlay = (<LoadingOverlayComponent />);
            }

            const dateTime = new Date(payment.created_at);

            payments.push(
                <div className={'ride payment card mb-3 position-relative ' + extraTypeClass} key={payment.id}>
                    {loadingOverlay}
                    <div className={'card-header position-relative'}>
                        <div className={'date'}>
                            <div className={'daytime'}>
                                <strong>#{payment.id}</strong> - {DateService.formatDate(dateTime)}
                            </div>
                            <RemainingTime time={payment.remainingTime} />
                        </div>
                    </div>
                    <div className={'card-body'}>
                        <div className={'d-flex w-100'}>
                            <div className={'flex-grow-1'}>
                                <div className={'amount'}>{payment.amount} Kč</div>
                                <span className={'name'}>{user}</span>
                            </div>
                            <div className={'buttons'}>
                                <div>
                                    {button}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
        let qrCode;
        if (this.state.qrVisible) {
            qrCode = (
                <div className={'qr-container'}>
                    <div className={'qr-main-c'}>
                        <div className={'qr-header'}>
                            <div className={'info'}>
                                QR Platba: #{this.state.qrCodeId} <span className={'lc-icon-Navigation-right'} /> {this.state.qrCodeUser}
                            </div>
                            <div onClick={() => {this.setState({qrVisible: false})}} className={'close lc-icon-Delete'} />
                        </div>
                        <div className={'qr-main'}>
                            <img src={this.state.qrCode} />
                            <div className={'bank-info'}>
                                <div>
                                Bank Account: <strong>{this.state.qrPaymentInfo.accountPrefix ? this.state.qrPaymentInfo.accountPrefix + '/' : '' }
                                {this.state.qrPaymentInfo.bankAccount}/{this.state.qrPaymentInfo.bankCode}</strong>
                                </div>
                                <div>
                                Variable symbol: <strong>{this.state.qrPaymentInfo.vsSymbol}</strong>
                                </div>
                                <div>
                                Constant symbol: <strong>{this.state.qrPaymentInfo.ksSymbol}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'qr-closer'} onClick={() => {this.setState({qrVisible: false})}}>
                    </div>
                </div>
            );
        }
        return (
            <>
                {qrCode}
                {payments}
            </>
        );
    }
}

export default Payments;
