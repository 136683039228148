import React from 'react';
import './App.scss';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Layout from './views/layout';
import Dashboard from './views/dashboard/dashboard';
import Login from './views/login/login';
import authService from './services/auth.service';
import MyAccount from './views/my-account/my-account';
import LoadingOverlayComponent from './components/loading-overlay/loading-overlay.component';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MyRoutes from './views/my-routes/my-routes';
import MyCalendar from './views/my-calendar/my-calendar';

const PrivateWrapper = () => {
    const allowed = authService.isAuthenticated;
    return allowed ? <Outlet /> : <Navigate to="/login" />;
}

const NonPrivateWrapper = () => {
    const allowed = !authService.isAuthenticated;
    return allowed ? <Outlet /> : <Navigate to="/" />;
}

class App extends React.Component<{}, {loading: boolean}> {
    constructor(props: {}) {
        super(props);
        this.state = {
            loading: true
        }
        authService.isLoggedIn().then((logged) => {
            this.setState({loading: false});
        })
    }
    render() {
        if (this.state.loading) {
            return (<div className={'splash-screen'}><LoadingOverlayComponent /></div>);
        }
      return (
          <>
          <ToastContainer autoClose={3000} closeOnClick position={'bottom-right'} />
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route element={<NonPrivateWrapper />}>
                      <Route path="/login" element={<Login />} />
                  </Route>
                  <Route element={<PrivateWrapper />}>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="*" element={<Dashboard />} />
                      <Route path="/my-account" element={<MyAccount />} />
                      <Route path="/my-routes" element={<MyRoutes />} />
                      <Route path="/my-calendar" element={<MyCalendar />} />
                  </Route>
              </Route>

          </Routes>
          </>
      );
    }
}

export default App;
