import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import MainHeaderComponent from '../components/main-header/main-header.component';

class Layout extends Component {
    render() {
        return (
            <main>
                <MainHeaderComponent />
                <div className={'page-container'}>
                    <Outlet />
                </div>
            </main>
        );
    }
}

export default Layout;
