import React from 'react';
import './loading-overlay.component.scss';
import { CircularProgress } from '@material-ui/core';
class UnresolvedRides extends React.Component<any, any> {

    render() {
        let extraClass = '';
        if (this.props.inline) {
            extraClass += ' inline';
        }
        return (
            <div className={'loading-overlay' + extraClass + ' ' + this.props.size + ' ' + this.props.theme} style={{height: this.props.height ? this.props.height : null}}>
                <CircularProgress />
            </div>
        );
    }
}

export default UnresolvedRides;
