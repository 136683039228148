import React from 'react';
import './budget.component.scss';
import { Route } from '../../models/route.model';
import LoadingOverlayComponent from '../loading-overlay/loading-overlay.component';

class Budget extends React.Component<{routes: Route[], selectedRoute: number, budget: number, budgetLoading: boolean}, any> {

    render() {
        if (!this.props.routes.length) {
            return (
                <LoadingOverlayComponent inline={true} height={'59px'} size={'xs'} />
            );
        }
        const route = this.props.routes[this.props.selectedRoute]

        let budget = null;
        if (this.props.budgetLoading) {
            budget = (<span><LoadingOverlayComponent size={'xs'} theme={'light'} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>);
        } else {
            budget = (<>{this.props.budget} Kč</>);
        }
        let budgetExtraClass = '';
        if (this.props.budget < 0) {
            budgetExtraClass = 'danger';
        } else {
            budgetExtraClass = 'success';
        }

        return (
           <div className={'budget'}>
               <div className={'calculation'}>
                   <div><span className={'lc-icon-Pricing'}/> &nbsp; <strong>{route.price_per_kilometer}</strong> Kč/km</div>
                   <div><span className={'lc-icon-Ruler'}/> &nbsp; <strong>{route.distance}</strong> km</div>
               </div>
               <div className={'total position-relative ' + budgetExtraClass}>{budget}</div>
           </div>
        );
    }
}

export default Budget;
