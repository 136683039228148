import React from 'react';
import './no-item-found.component.scss';
class NoItemFound extends React.Component<any, any> {

    render() {
        let extraClass = '';
        if (this.props.inline) {
            extraClass += ' inline';
        }
        return (
            <div className={'no-item-found' + extraClass + ' ' + this.props.size}>
                <span className={'lc-icon-Car'} /> &nbsp; &nbsp;
                <span className={'label'}>{this.props.label ? this.props.label : 'No record found!' }</span>
            </div>
        );
    }
}

export default NoItemFound;
