import { TOKEN_STORAGE } from './auth.service';

export class ApiService {
    public async get(url: string, extraHeaders?: any): Promise<any> {
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'If-Modified-Since': '0', 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_STORAGE) }
        });
        return await response.json();
    }

    public async post(url: string, body: any, extraHeaders?: any): Promise<any> {
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'If-Modified-Since': '0', 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_STORAGE) },
            body: JSON.stringify(body)
        })
        return await response.json();
    }

    public async put(url: string, body: any, extraHeaders?: any): Promise<any> {
        const response = await fetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'If-Modified-Since': '0', 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_STORAGE) },
            body: JSON.stringify(body)
        })
        return await response.json();
    }

    public async delete(url: string, body: any, extraHeaders?: any): Promise<any> {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'If-Modified-Since': '0', 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_STORAGE) },
            body: JSON.stringify(body)
        })
        return await response.json();
    }

    public async uploadPost(url: string, body: any): Promise<any> {
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_STORAGE) },
            body: body
        })
        return await response.json();
    }

}

const apiService = new ApiService();
export default apiService;
