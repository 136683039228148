import React from 'react';
import './my-calendar.scss';
import LoadingOverlayComponent from '../../components/loading-overlay/loading-overlay.component';
import { Route } from '../../models/route.model';
import RouteService from '../../services/routes.service';
import RouteSelector from '../../components/route-selector/route-selector.component';
import { CancelTerm } from '../../models/cancel-term.model';
import DateService from '../../services/date.service';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ExtraRide } from '../../models/ExtraRide.model';
import NoItemFound from '../../components/no-item-found/no-item-found.component';
import { toast } from 'react-toastify';

class MyCalendar extends React.Component<{}, {
    loading: boolean,
    myRoutes: Route[],
    selectedRoute: number,
    cancelTerms: CancelTerm[],
    loadingCancelTerms: boolean,
    addNewCancelTerm: CancelTerm,
    addNewExtraRide: ExtraRide,
    isAddCancelTermVisible: boolean,
    loadingAddCancelTerms: boolean,
    loadingAddExtraRide: boolean,
    isAddExtraRideVisible: boolean,
    loadingExtraRides: boolean,
    extraRides: ExtraRide[],
    }> {
    routeService;
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            myRoutes: [],
            selectedRoute: 0,
            cancelTerms: [],
            loadingCancelTerms: false,
            addNewCancelTerm: {
                date_from: new Date(),
                date_to: new Date(),
                route_id: -1,
                apply_for_all: 0,
            },
            addNewExtraRide: {
                date: new Date(),
                route_id: -1,
            },
            isAddCancelTermVisible: false,
            loadingAddCancelTerms: false,
            isAddExtraRideVisible: false,
            loadingAddExtraRide: false,
            loadingExtraRides: false,
            extraRides: [],
        };
        this.routeService = new RouteService();
    }

    async loadRoutes() {
        this.setState({loading: true});
        const response = await this.routeService.getJoinedRoutes();
        if (response.length) {
            this.setState({myRoutes: response, loading: false}, async () => {
                this.reloadCalendar().then();
            });
        } else {
            this.setState({loading: false});
        }
    }

    async setSelectedRoute(index: number) {
        this.setState({selectedRoute: index}, async () => {
            this.reloadCalendar().then();
        });
    }

    async reloadCalendar() {
        this.reloadCancelTerms().then();
        this.reloadExtraTerms().then();
    }

    async reloadCancelTerms() {
        this.setState({loadingCancelTerms: true});
        const cancelTerms = await this.routeService.getMyRoutesCancelTerms(this.state.myRoutes[this.state.selectedRoute].id);
        this.setState({loadingCancelTerms: false, cancelTerms: cancelTerms});
    }

    async reloadExtraTerms() {
        this.setState({loadingExtraRides: true});
        const extraRides = await this.routeService.getMyRoutesExtraRides(this.state.myRoutes[this.state.selectedRoute].id);
        this.setState({loadingExtraRides: false, extraRides: extraRides});
    }

    componentDidMount() {
        this.loadRoutes().then();
    }

    async deleteCancelTerm(cancelTerm: CancelTerm) {
        if (window.confirm("Are you sure you want to delete the item?")) {
            this.setState({loadingCancelTerms: true});
            const response = await this.routeService.deleteCancelTerm(cancelTerm.id!);
            if (response.status === 'ok') {
                toast.success("Item removed!", {
                    autoClose: 2000,
                })
            }
            await this.reloadCancelTerms();
        }
    }

    async deleteExtraTerm(extraTerm: ExtraRide) {
        if (window.confirm("Are you sure you want to delete the item?")) {
            this.setState({loadingExtraRides: true});
            const response = await this.routeService.deleteExtraTerm(extraTerm.id!);
            if (response.status === 'ok') {
                toast.success("Item removed!", {
                    autoClose: 2000,
                })
            }
            await this.reloadExtraTerms();
        }
    }

    handleDateChange(target: any, what: string, date: Date) {
        target[what] = date;
        this.setState({});
    }

    handleCheckboxChange(target: any, e: any) {
        // @ts-ignore
        // eslint-disable-next-line
        this.state.addNewCancelTerm[target] = e.target.checked ? 1 : 0;
    }

    async addNewCancelTerm() {
        this.setState({loadingAddCancelTerms: true});
        const copy = JSON.parse(JSON.stringify(this.state.addNewCancelTerm));
        copy.route_id = this.state.myRoutes[this.state.selectedRoute].id;
        const fromDate = new Date(copy.date_from);
        fromDate.setHours(0, 0,  0);
        copy.date_from = DateService.formatDateForServer(fromDate);
        const toDate = new Date(copy.date_to);
        toDate.setHours(23, 59,  59);
        copy.date_to = DateService.formatDateForServer(toDate);
        const response = await this.routeService.addNewCancelTerm(copy);
        if (response.status === 'ok') {
            toast.success("Term added!", {
                autoClose: 2000,
            })
        }
        this.setState({isAddCancelTermVisible: false, loadingAddCancelTerms: false});
        await this.reloadCancelTerms();
    }

    async addNewExtraRide() {
        this.setState({loadingAddExtraRide: true});
        const copy = JSON.parse(JSON.stringify(this.state.addNewExtraRide));
        copy.route_id = this.state.myRoutes[this.state.selectedRoute].id;
        const date = new Date(copy.date);
        date.setHours(0, 0,  0);
        copy.date = DateService.formatDateForServer(date);
        const response = await this.routeService.addNewExtraTerm(copy);
        if (response.status === 'ok') {
            toast.success("Extra ride added!", {
                autoClose: 2000,
            })
        }
        this.setState({isAddExtraRideVisible: false, loadingAddExtraRide: false});
        await this.reloadExtraTerms();
    }

    render() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let loading = null
        if (this.state.loading) {
            return (<LoadingOverlayComponent inline={true}/>)
        }

        let terms = [];
        for (const term of this.state.cancelTerms) {
            const dateFrom = new Date(term.date_from!);
            const dateTo = new Date(term.date_to!);
            terms.push(
                <div className={'card mb-1'} key={'term-' + term.id}>
                    <div className={'card-body cancel-term'}>
                        <strong>{DateService.formatDate(dateFrom) + ' - ' + DateService.formatDate(dateTo)}</strong>
                        <button className={'btn text-danger'} onClick={() => {this.deleteCancelTerm(term).then()}}><span className={'lc-icon-Delete-alt'} /></button>
                    </div>
                </div>
            );
        }
        if (!this.state.cancelTerms.length) {
            terms.push(<NoItemFound key={'cancel-not-found'} />);
        }
        if (this.state.loadingCancelTerms) {
            terms = [];
            terms.push(<LoadingOverlayComponent key={'loading'} inline={true} />)
        }

        let cancelTermVisible;
        if (this.state.isAddCancelTermVisible) {
            let loadingAddNewCancelTerm;
            if (this.state.loadingAddCancelTerms) {
                loadingAddNewCancelTerm = (<LoadingOverlayComponent />);
            }
            cancelTermVisible = (
                <div className={'card add-container mt-1 position-relative'}>
                    {loadingAddNewCancelTerm}
                    <div className={'card-header'}>
                        <strong>Add new cancel term</strong>
                    </div>
                    <div className={'card-body'}>
                        <div className={'row'}>
                            <div className={'col col-sm-6 w-50'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker label={'From date'}  className={'date-picker'} name={'dateFrom'} value={this.state.addNewCancelTerm.date_from} onChange={(e: any) => {this.handleDateChange(this.state.addNewCancelTerm, 'date_from', e)}} />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={'col col-sm-6 w-50'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker label={'To date'}  className={'date-picker'} name={'dateTo'} value={this.state.addNewCancelTerm.date_to} onChange={(e: any) => {this.handleDateChange(this.state.addNewCancelTerm, 'date_to', e)}} />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={'col-sm-12'}>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={this.state.addNewCancelTerm.apply_for_all} onChange={(e: any) => {this.handleCheckboxChange('apply_for_all', e)}} type="checkbox" id="flexSwitchCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Apply for all in ride</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'card-footer'}>
                        <div className={'btn-group d-flex'}>
                            <button className={'btn btn-inverted w-100'} onClick={() => this.addNewCancelTerm()}><span className={'lc-icon-Floppy'} /> &nbsp; Save</button>
                            <button className={'btn btn-inverted w-100'} onClick={() => this.setState({isAddCancelTermVisible: false})} style={{opacity: .8}}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            cancelTermVisible = (
                <div className={'text-center mt-2'}>
                    <button className={'btn btn-primary'} onClick={() => {this.setState({isAddCancelTermVisible: true})}}><span className={'lc-icon-Add'}/> &nbsp;Add new term</button>
                </div>
            );
        }

        let extraRides = [];
        for (const extra of this.state.extraRides) {
            const date = new Date(extra.date!);
            extraRides.push(
                <div className={'card mb-1'} key={'extra-' + extra.id}>
                    <div className={'card-body cancel-term'}>
                        <strong>{days[date.getDay()]} - {DateService.formatDate(date)}</strong>
                        <button className={'btn text-danger'} onClick={() => {this.deleteExtraTerm(extra).then()}}><span className={'lc-icon-Delete-alt'} /></button>
                    </div>
                </div>
            );
        }
        if (!this.state.extraRides.length) {
            extraRides.push(<NoItemFound key={'no-item-found'} />);
        }
        if (this.state.loadingExtraRides) {
            extraRides = [];
            extraRides.push(<LoadingOverlayComponent key={'loading-1'} inline={true} />)
        }

        let extraRideVisible;
        if (this.state.isAddExtraRideVisible) {
            let loadingAddExtraRide;
            if (this.state.loadingAddExtraRide) {
                loadingAddExtraRide = (<LoadingOverlayComponent />);
            }
            extraRideVisible = (
                <div className={'card add-container mt-1 position-relative'}>
                    {loadingAddExtraRide}
                    <div className={'card-header'}>
                        <strong>Add extra term</strong>
                    </div>
                    <div className={'card-body'}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker label={'New date'} className={'date-picker w-100'} name={'date'} value={this.state.addNewExtraRide.date} onChange={(e: any) => {this.handleDateChange(this.state.addNewExtraRide, 'date', e)}} />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={'card-footer'}>
                        <div className={'btn-group d-flex'}>
                            <button className={'btn btn-inverted w-100'} onClick={() => this.addNewExtraRide()}><span className={'lc-icon-Floppy'} /> &nbsp; Save</button>
                            <button className={'btn btn-inverted w-100'} onClick={() => this.setState({isAddExtraRideVisible: false})} style={{opacity: .8}}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            extraRideVisible = (
                <div className={'text-center mt-2'}>
                    <button className={'btn btn-primary'} onClick={() => {this.setState({isAddExtraRideVisible: true})}}><span className={'lc-icon-Add'}/> &nbsp;Add extra term</button>
                </div>
            );
        }
        return (
            <div className={'my-calendar position-relative'}>
                <section>
                    <RouteSelector routes={this.state.myRoutes} selectedRoute={this.state.selectedRoute} setSelectedRoute={this.setSelectedRoute.bind(this)}/>
                </section>
                <h2>Cancel terms</h2>
                {terms}
                {cancelTermVisible}

                <h2 className={'mt-5'}>Extra rides</h2>
                {extraRides}
                {extraRideVisible}

                {loading}
            </div>
        );
    }
}

export default MyCalendar;
