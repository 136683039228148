import React from 'react';
import { Ride } from '../../models/ride.model';
import './history.component.scss';
import LoadingOverlayComponent from '../loading-overlay/loading-overlay.component';
import authService from '../../services/auth.service';
import NoItemFound from '../no-item-found/no-item-found.component';
import DateService from '../../services/date.service';

class History extends React.Component<
    {resolvedRides: Ride[], loading: boolean},
    {}> {

    render() {

        if (this.props.loading) {
            return (
                <LoadingOverlayComponent inline={true} />
            );
        }

        if (!this.props.resolvedRides.length) {
            return (<NoItemFound />);
        }

        const rides = [];
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        for (const ride of this.props.resolvedRides) {
            const dateTime = new Date(ride.daytime);
            const users = [];
            if (!ride.type) {
                for (const passenger of ride.ride_user!) {
                    if (passenger.pivot.status === 2) {
                        continue;
                    }
                    const letters = passenger.forename!.substring(0, 1) + passenger.surname!.substring(0, 1);
                    const nameShort = (<div className={'name-short'}>{letters}</div>)

                    let driver = null;
                    if (passenger.id === ride.driver_user_id) {
                        driver = (<span className={'driver'}></span>);
                    }
                    users.push(
                        <div className={'user-icon'} key={passenger.id} style={{backgroundImage: passenger.profile?.logo ? 'url(' + passenger.profile.logo + ')' : undefined}}>
                            {driver}
                            {nameShort}
                        </div>
                    );
                }
            }

            let extraClass = '';
            if (ride.driver_user_id === authService.user.id) {
                extraClass += ' is-driver';
            }
            if (!ride.type) {
                rides.push(
                    <div className={'history-item' + extraClass} key={ride.id}>
                        <div className={'date'}><strong>{days[dateTime.getDay()]}</strong> {DateService.formatDate(dateTime)}</div> <div className={'users-list'}>{users}</div>
                    </div>
                )
            } else if (ride.type === 1) {
                rides.push(
                    <div className={'history-item success' + extraClass} key={'event_' + ride.id}>
                        <span className={'lc-icon-List-alt'} /> &nbsp; Payments generated ({DateService.formatDate(dateTime)})
                    </div>
                )
            }
        }

        return (
            <div className={'history-list'}>
                {rides}
            </div>
        );
    }
}

export default History;
