import React from 'react';
import History from '../../components/history/history.component';
import Budget from '../../components/budget/budget.component';
import RouteSelector from '../../components/route-selector/route-selector.component';
import { Route } from '../../models/route.model';
import RouteService from '../../services/routes.service';
import UnresolvedRides from '../../components/unresolved-rides/unresolved-rides.component';
import { Ride } from '../../models/ride.model';
import { Payment } from '../../models/payment.model';
import Payments from '../../components/payments/payments.component';
import AddNewRide from "../../components/add-new-ride/add-new-ride.component";

class Dashboard extends React.Component<
    any,
    {
        myRoutes: Route[],
        selectedRoute: number,
        unresolvedRides: Ride[],
        unresolvedRidesLoading: boolean,
        payPaymentsLoading: boolean,
        payPayments: Payment[],
        receivePaymentsLoading: boolean,
        receivePayments: Payment[],
        resolvedRides: Ride[],
        resolvedRidesLoading: boolean,
        budget: number;
        budgetLoading: boolean;
    }> {
    routeService;
    constructor(props: any) {
        super(props);
        this.state = {
            myRoutes: [],
            selectedRoute: 0,
            unresolvedRides: [],
            unresolvedRidesLoading: true,
            resolvedRides: [],
            resolvedRidesLoading: true,
            payPaymentsLoading: false,
            payPayments: [],
            receivePaymentsLoading: false,
            receivePayments: [],
            budget: 0,
            budgetLoading: false
        };
        this.routeService = new RouteService();
        this.loadRoutes().then();
    }

    async loadRoutes() {
        const response = await this.routeService.getJoinedRoutes();
        if (response.length) {
            this.setState({myRoutes: response}, async () => {
                this.reloadDashboard().then();
            });
        }
    }

    async setSelectedRoute(index: number) {
        this.setState({selectedRoute: index}, async () => {
            this.reloadDashboard().then();
        });
    }

    async reloadUnresolvedRides() {
        if (this.state.myRoutes.length) {
            this.setState({unresolvedRidesLoading: true})
            const unresolvedRides = await this.routeService.getUnresolvedRides(this.state.myRoutes[this.state.selectedRoute].id);
            this.setState({unresolvedRides: unresolvedRides, unresolvedRidesLoading: false});
        }
    }

    async reloadResolvedRides() {
        if (this.state.myRoutes.length) {
            this.setState({resolvedRidesLoading: true})
            const resolvedRides = await this.routeService.getResolvedRides(this.state.myRoutes[this.state.selectedRoute].id);
            this.setState({resolvedRides: resolvedRides, resolvedRidesLoading: false});
        }
    }

    async reloadPayPayments() {
        if (this.state.myRoutes.length) {
            this.setState({payPaymentsLoading: true})
            const payments = await this.routeService.getPayPayments(this.state.myRoutes[this.state.selectedRoute].id);
            this.setState({payPayments: payments, payPaymentsLoading: false});
        }
    }

    async reloadReceivePayments() {
        if (this.state.myRoutes.length) {
            this.setState({receivePaymentsLoading: true})
            const payments = await this.routeService.getReceivePayments(this.state.myRoutes[this.state.selectedRoute].id);
            this.setState({receivePayments: payments, receivePaymentsLoading: false});
        }
    }

    async calculateBudget() {
        if (this.state.myRoutes.length) {
            this.setState({budgetLoading: true});
            const response = await this.routeService.getRoadBudget(this.state.myRoutes[this.state.selectedRoute].id);
            this.setState({budget: response.budget, budgetLoading: false});
        }
    }

    async reloadDashboard() {
        this.reloadUnresolvedRides().then();
        this.reloadResolvedRides().then();
        this.reloadPayPayments().then();
        this.reloadReceivePayments().then();
        this.calculateBudget().then();
    }

    render() {
        let receivePayment;
        if (this.state.receivePayments.length) {
            receivePayment = (
                <section>
                    <h2>Awaiting payments</h2>
                    <Payments payments={this.state.receivePayments} reloadPayments={this.reloadReceivePayments.bind(this)} type={'income'} loading={this.state.receivePaymentsLoading} />
                </section>
            )
        }
        let payPayment;
        if (this.state.payPayments.length) {
            payPayment = (
                <section>
                    <h2>Outgoing payments</h2>
                    <Payments payments={this.state.payPayments} reloadPayments={this.reloadPayPayments.bind(this)} type={'outcome'} loading={this.state.payPaymentsLoading} />
                </section>
            )
        }
        return (
            <>
                <section>
                    <RouteSelector routes={this.state.myRoutes} selectedRoute={this.state.selectedRoute} setSelectedRoute={this.setSelectedRoute.bind(this)}/>
                </section>
                <section>
                    <Budget routes={this.state.myRoutes} selectedRoute={this.state.selectedRoute} budget={this.state.budget} budgetLoading={this.state.budgetLoading} />
                </section>
                {receivePayment}
                {payPayment}
                <section>
                    <AddNewRide selectedRoute={this.state.myRoutes[this.state.selectedRoute]} reloadUnresolvedRides={this.reloadDashboard.bind(this)}></AddNewRide>
                </section>
                <section>
                    <h2>Unresolved rides</h2>
                    <UnresolvedRides unresolvedRides={this.state.unresolvedRides} loading={this.state.unresolvedRidesLoading} reloadUnresolvedRides={this.reloadDashboard.bind(this)} />
                </section>
                <section>
                    <h2>History {this.state.resolvedRidesLoading}</h2>
                    <History resolvedRides={this.state.resolvedRides} loading={this.state.resolvedRidesLoading} />
                </section>
            </>
        );
    }
}

export default Dashboard;
