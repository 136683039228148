import React  from 'react';
import './add-new-ride.component.scss';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LoadingOverlayComponent from "../loading-overlay/loading-overlay.component";
import {ExtraRide} from "../../models/ExtraRide.model";
import DateService from "../../services/date.service";
import {toast} from "react-toastify";
import {Route} from "../../models/route.model";
import RouteService from "../../services/routes.service";
import {TextField} from "@material-ui/core";

class AddNewRide extends React.Component<{ selectedRoute: Route, reloadUnresolvedRides: any }, { displayAddExtraRideDialogue: boolean, loadingAddExtraRide?: any, addNewExtraRide: ExtraRide}> {
    routeService;
    constructor(props:any) {
        super(props);
        this.state = {
            displayAddExtraRideDialogue: false,
            addNewExtraRide: {
                date: new Date(),
                route_id: -1,
                note: '',
            },
        };
        this.routeService = new RouteService();
    }

    handleDateChange(target: any, what: string, date: Date) {
        target[what] = date;
        this.setState({});
    }


    async addNewExtraRide() {
        if (!window.confirm('Are you sure you want to add new ride?')) {
            return;
        }
        this.setState({loadingAddExtraRide: true});
        const copy = JSON.parse(JSON.stringify(this.state.addNewExtraRide));
        copy.route_id = this.props.selectedRoute.id;
        const date = new Date(copy.date);
        date.setHours(0, 0,  0);
        copy.date = DateService.formatDateForServer(date);
        const response = await this.routeService.addNewExtraRide(copy, this.props.selectedRoute.id);
        if (response.status === 'ok') {
            toast.success("Extra ride added!", {
                autoClose: 2000,
            })
        }
        this.setState({displayAddExtraRideDialogue: false, loadingAddExtraRide: false});
        this.props.reloadUnresolvedRides();
    }

    render() {
        let addExtraRideDialogue = null;
        if (this.state.displayAddExtraRideDialogue) {
            let loadingAddExtraRide;
            if (this.state.loadingAddExtraRide) {
                loadingAddExtraRide = (<LoadingOverlayComponent />);
            }
            addExtraRideDialogue = (
                <div className={'card add-container mt-1 position-relative'}>
                    {loadingAddExtraRide}
                    <div className={'card-header'}>
                        <strong>Add extra ride</strong>
                    </div>
                    <div className={'card-body'}>
                        <div className={'form-line'}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker label={'New date'} className={'date-picker w-100'} name={'date'} value={this.state.addNewExtraRide.date} onChange={(e: any) => {this.handleDateChange(this.state.addNewExtraRide, 'date', e)}} />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className={'card-footer'}>
                        <div className={'btn-group d-flex'}>
                            <button className={'btn btn-inverted w-100'} onClick={() => this.addNewExtraRide()}><span className={'lc-icon-Floppy'} /> &nbsp; Save</button>
                            <button className={'btn btn-inverted w-100'} onClick={() => this.setState({displayAddExtraRideDialogue: false})} style={{opacity: .8}}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className={'btn btn-primary btn-block'} onClick={() => {this.setState({displayAddExtraRideDialogue: !this.state.displayAddExtraRideDialogue})}}>
                    <span className={'lc-icon-Add'}></span> Add extra ride
                </div>
                {addExtraRideDialogue}
            </div>
        );
    }
}

export default AddNewRide;
