class CryptoService {
    encrypt(salt: string, text: string) {
        const textToChars = (text: string) => text.split("").map((c: string) => c.charCodeAt(0));
        const byteHex = (n: string) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text
            .split("")
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join("");
    }

    decrypt(salt: string, encoded: any) {
        const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded
            .match(/.{1,2}/g)
            .map((hex: any) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode: any) => String.fromCharCode(charCode))
            .join("");
    }

}
const cryptoService = new CryptoService();
export default cryptoService;