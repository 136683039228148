import React from 'react';
import './remaining-time.component.scss';

class RemainingTime extends React.Component<{time: number}, {}> {
    toHHMMSS() {
        const sec_num = this.props.time;
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        // @ts-ignore
        if (Math.abs(hours) < 10) {hours   = "0"+hours;}
        // @ts-ignore
        if (minutes < 10) {minutes = "0"+minutes;}
        // @ts-ignore
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours + 'h '+minutes + 'm ' + seconds + 's';
    }

    render() {
        if (!this.props.time) {
            return null;
        }
        return (
            <div className={'clock'}>
                <span className={'lc-icon-Time'} /> &nbsp;{this.toHHMMSS()}
            </div>
        );
    }
}

export default RemainingTime;
