import React from 'react';
import { Ride } from '../../models/ride.model';
import './unresolved-rides.component.scss';
import RideService from '../../services/ride.service';
import LoadingOverlayComponent from '../loading-overlay/loading-overlay.component';
import { toast } from 'react-toastify';
import authService from '../../services/auth.service';
import RemainingTime from '../remaining-time/remaining-time.component';
import NoItemFound from '../no-item-found/no-item-found.component';
import DateService from '../../services/date.service';
class UnresolvedRides extends React.Component<
    {unresolvedRides: Ride[], reloadUnresolvedRides: any, loading: boolean},
    {}> {
    rideService;

    constructor(props:any) {
        super(props);
        this.rideService = new RideService();
        this.updateTimeClock();
    }

    updateTimeClock() {
        setInterval(() => {
            for (const ride of this.props.unresolvedRides) {
                const today = new Date().getTime();
                let timeLimit = new Date(ride.daytime).getTime() + (5 * 24 * 60 * 60 * 1000);
                const timeRemaining = Math.round((timeLimit - today) / 1000);
                ride.remainingTime = timeRemaining;
            }
            this.setState({});
        }, 1000)
    }

    async acceptRide(ride: Ride) {
        ride.loading = true;
        this.setState({});
        const response = await this.rideService.acceptRide(ride);
        if (response.status === 'ok') {
            toast.success("Ride accepted!", {
                autoClose: 2000,
            });
        }
        this.props.reloadUnresolvedRides();
    }

    async acceptRideDriver(ride: Ride) {
        ride.loading = true;
        this.setState({});
        const response = await this.rideService.acceptRideDriver(ride);
        if (response.status === 'ok') {
            toast.success("Ride accepted as driver!", {
                autoClose: 2000,
            })
        }
        this.props.reloadUnresolvedRides();
    }

    async declineRide(ride: Ride) {
        ride.loading = true;
        this.setState({});
        const response = await this.rideService.declineRide(ride);
        if (response.status === 'ok') {
            toast.success("Ride declined!", {
                autoClose: 2000,
            })
        }
        this.props.reloadUnresolvedRides();
    }

    async markRideAsFinished(ride: Ride) {
        ride.loading = true;
        this.setState({});
        const response = await this.rideService.markRideAsFinished(ride);
        if (response.status === 'ok') {
            toast.success("Ride finished!", {
                autoClose: 2000,
            })
        }
        this.props.reloadUnresolvedRides();
    }

    someoneIsDriver(ride: Ride) {
        return ride.driver_user_id;
    }

    allAccepted(ride: Ride) {
        if (ride.ride_user) {
            for (const user of ride.ride_user) {
                if (user.pivot.status === 0) {
                    return false;
                }
            }

            return true;
        }
    }

    render() {
        const rides = [];
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        if (this.props.loading) {
            return (
                <LoadingOverlayComponent inline={true} />
            );
        }

        if (!this.props.unresolvedRides.length) {
            return (<NoItemFound />);
        }

        for (const ride of this.props.unresolvedRides) {
            let loadingOverlay = null;
            if (ride.loading) {
                loadingOverlay = (<LoadingOverlayComponent />);
            }
            const passengers = [];
            for (const passenger of ride.ride_user!) {
                let extraPassengerClass = '';
                if (passenger.pivot.status === 1) {
                    extraPassengerClass += ' accepted';
                } else if (passenger.pivot.status === 2) {
                    extraPassengerClass += ' declined';
                } else {
                    extraPassengerClass += ' idle';
                }


                const letters = passenger.forename!.substring(0, 1) + passenger.surname!.substring(0, 1);
                const nameShort = (<div className={'nameShort'}>{letters}</div>)

                passengers.push(
                    <div key={passenger.id} className={'passenger' + extraPassengerClass} style={{backgroundImage: passenger.profile?.logo ? 'url(' + passenger.profile.logo + ')' : undefined}}>
                        <div className={'icon accepted'}><span className={'lc-icon-Check'} /></div>
                        <div className={'icon declined'}><span className={'lc-icon-Delete'} /></div>
                        <div className={'icon idle'}><span className={'lc-icon-Time'} /></div>
                        {nameShort}
                    </div>
                );
            }

            let buttons = null;
            if (ride.my_status && ride.my_status.status === 0) {

                const driverButton = [];
                if (!this.someoneIsDriver(ride)) {
                    driverButton.push(<button key={'acceptDriver'} className={'btn btn-success btn-sm'} onClick={() => {this.acceptRideDriver(ride).then()}}><span className={'lc-icon-Car'}/>&nbsp;Driver</button>);
                }
                if (passengers.length !== 1) {
                    driverButton.push(<button key={'accept'} className={'btn btn-primary btn-sm'} onClick={() => {this.acceptRide(ride).then()}}><span className={'lc-icon-Check'}/>&nbsp;Passenger</button>);
                }
                buttons = (
                    <div className={'btn-group action-buttons btn-group-justified w-100'}>
                        {driverButton}
                        <button className={'btn btn-danger btn-sm'} onClick={() => {this.declineRide(ride).then()}}><span className={'lc-icon-Delete'}/>&nbsp;Decline</button>
                    </div>
                );
            } else if (this.allAccepted(ride)) {
                buttons = (
                    <div className={'btn-group action-buttons btn-group-justified w-100'}>
                        <button className={'btn btn-primary btn-sm'} onClick={() => {this.markRideAsFinished(ride).then()}}><span className={'lc-icon-Check'}/>&nbsp;Finish ride</button>
                    </div>
                    );
            }

            const dateTime = new Date(ride.daytime);

            let extraRideClass = '';
            if (!ride.my_status) {
            } else if (ride.my_status.status === 1) {
                extraRideClass += ' accepted';
            } else if (ride.my_status.status === 2) {
                extraRideClass += ' declined';
            }

            let extraIcons = [];
            if (ride.driver_user_id === authService.user.id) {
                extraIcons.push(<div className={'icon is-driver'} key={'is-driver-icon'}><span className={'lc-icon-Car'} /></div>);
            }

            rides.push(
                <div className={'ride card mb-3 position-relative' + extraRideClass} key={ride.id}>
                    {loadingOverlay}
                    <div className={'card-header position-relative'}>
                        <div className={'date'}>
                            <div className={'daytime'}>
                                <span className={'day-name'}>{days[dateTime.getDay()]}</span>
                                {DateService.formatDate(dateTime)}
                            </div>
                            <RemainingTime time={ride.remainingTime} />
                        </div>
                        {extraIcons}
                    </div>
                    <div className={'card-body'}>
                        <div className={'passengers'}>
                            {passengers}
                        </div>
                        {buttons}
                    </div>
                </div>
            );
        }
        return (
            <>
                {rides}
            </>
        );
    }
}

export default UnresolvedRides;
