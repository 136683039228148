import React from 'react';
import './my-routes.scss';
import LoadingOverlayComponent from '../../components/loading-overlay/loading-overlay.component';
import RouteService from '../../services/routes.service';
import { Route } from '../../models/route.model';

class MyRoutes extends React.Component<{}, {loading: boolean, routes: Route[], overlayLoading: boolean}> {
    routeService;
    constructor(props: any) {
        super(props);
        this.state = {loading: false, routes: [], overlayLoading: false};
        this.routeService = new RouteService();
    }

    componentDidMount() {
        this.loadMyRoutes().then();
    }

    async loadMyRoutes() {
        this.setState({loading: true});
        const response = await this.routeService.getMyRoutes();
        this.setState({routes: response, loading: false});
    }

    removeMyRoute() {
        console.log('removeMyRoute')
    }

    render() {
        if (this.state.loading) {
            return (<LoadingOverlayComponent inline={true}/>);
        }

        let loading = null
        if (this.state.overlayLoading) {
            loading = (<LoadingOverlayComponent />);
        }
        const myRoutes = [];
        const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        for (const route of this.state.routes) {
            const users = [];
            for (const passenger of route.all_route_user) {
                let nameShort = null;
                if (!passenger.profile?.logo) {
                    const letters = passenger.forename!.substring(0, 1) + passenger.surname!.substring(0, 1);
                    nameShort = (<div className={'name-short'}>{letters}</div>)
                }
                users.push(
                    <div key={passenger.id} className={'user-icon'} style={{backgroundImage: passenger.profile?.logo ? 'url(' + passenger.profile.logo + ')' : undefined}}>
                        {nameShort}
                    </div>
                );
            }
            const days = [];
            for (const route_day of route.route_day) {

                days.push(
                    <div key={route_day.week_day} className={'week-day'}>
                        {weekDays[route_day.week_day]}
                    </div>
                );
            }
            myRoutes.push(
              <div className={'card my-routes'} key={route.id}>
                  <div className={'card-header d-flex align-items-center'}>
                      <strong className={'flex-grow-1'}>{route.title}</strong>
                      <div className={'btn text-danger'} onClick={() => {this.removeMyRoute()}}>
                          <span className={'lc-icon-Delete'} />
                      </div>
                  </div>
                  <div className={'card-body'}>
                      <h2>Passengers</h2>
                      <div className={'users-list'}>
                        {users}
                      </div>
                  </div>
                  <div className={'card-body'}>
                      <h2>Regular rides every</h2>
                      {days}
                  </div>
                  <div className={'card-body'}>
                      <div className={'d-flex'}>
                          <div className={'w-50'}>
                            <h2>Price per Km</h2>
                            <strong>{route.price_per_kilometer} Kč</strong>
                          </div>
                          <div className={'w-50'}>
                              <h2>Distance</h2>
                              <strong>{route.distance} Kč</strong>
                          </div>
                      </div>
                  </div>
              </div>
            );
        }

        return (
            <div className={'my-account position-relative'}>
                <div className={'text-right form-line'}>
                    <div className={'btn btn-primary'}><span className={'lc-icon-Add'}></span> Add new route</div>
                </div>
                <div className={'card my-routes add-container hidden'}>
                    <div className={'card-header'}>
                        <input type={'text'} placeholder={'Route name...'} className={'form-control form-control-sm flex-grow-1'} />
                    </div>
                    <div className={'card-body'}>
                        <h2>Passengers</h2>
                        <div className={'search-by-email-user'}>
                            <div className={'input-group'}>
                                <input type={'text'} className={'form-control form-control-sm'} placeholder={'Search by email...'}/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline btn-sm" type="button"><span className={'lc-icon-Add'} /></button>
                                </div>
                            </div>
                            <div className={'searched-users-list'}>
                                <div className={'found-user'}><div className={'image'}/> <div className={'name'}>Tomáš Hubík (tomas.hubik1@gmail.com)</div></div>
                                <div className={'found-user'}><div className={'image'}/> <div className={'name'}>Tomáš Hubík (tomas.hubik1@gmail.com)</div></div>
                                <div className={'found-user'}><div className={'image'}/> <div className={'name'}>Tomáš Hubík (tomas.hubik1@gmail.com)</div></div>
                                <div className={'no-item-found'}><span className={'lc-icon-Absence'}/> &nbsp; No user found</div>
                            </div>
                        </div>
                        <div className={'users-list'}>
                            <div className={'user-icon'}>
                                TH
                                <div className={'remove-user'}>
                                    <span className={'lc-icon-Delete'} />
                                </div>
                            </div>
                            <div className={'no-user-found'}>No user selected</div>
                        </div>
                    </div>
                    <div className={'card-body'}>
                        <h2>Regular rides every</h2>
                        <div className={'btn-group'}>
                            <div className={'btn btn-sm btn-outline'}>Mon</div>
                            <div className={'btn btn-sm btn-primary'}>Tue</div>
                            <div className={'btn btn-sm btn-outline'}>Wed</div>
                            <div className={'btn btn-sm btn-outline'}>Thu</div>
                            <div className={'btn btn-sm btn-outline'}>Fri</div>
                            <div className={'btn btn-sm btn-outline'}>Sat</div>
                            <div className={'btn btn-sm btn-outline'}>Sun</div>
                        </div>
                    </div>
                    <div className={'card-body'}>
                        <div className={'d-flex row'}>
                            <div className={'w-50 col'}>
                                <h2>Price per Km</h2>
                                <div className={'input-group input-group-sm'}>
                                    <input type={'text'}  className={'form-control form-control-sm'}/>
                                    <div className={'input-group-append'}>
                                        <div className={'input-group-text'}>Kč</div>
                                    </div>
                                </div>
                            </div>
                            <div className={'w-50 col'}>
                                <h2>Distance</h2>
                                <div className={'input-group input-group-sm'}>
                                    <input type={'text'}  className={'form-control form-control-sm'}/>
                                    <div className={'input-group-append'}>
                                        <div className={'input-group-text'}>Km</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading}
                {myRoutes}
            </div>
        );
    }
}

export default MyRoutes;
