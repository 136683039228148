import React from 'react';
import './main-header.component.scss';
import { Link } from 'react-router-dom';
import authService from '../../services/auth.service';
import Brand from '../brand/brand.component';

class MainHeaderComponent extends React.Component {
    async logout() {
        await authService.logout();
    }
    render() {
        return (
            <header>
                <Brand />
                <nav className={'main-navigation'}>
                    <Link className={'link'} to="/my-routes"><span className={'lc-icon-Bulleted-list'}/></Link>
                    <Link className={'link'} to="/my-calendar"><span className={'lc-icon-Calendar-alt-2'}/></Link>
                    <Link className={'link'} to="/my-account"><span className={'lc-icon-User'}/></Link>
                    <a href={''} className={'link'} onClick={this.logout}><span className={'lc-icon-Logout'}/></a>
                </nav>
            </header>
        );
    }
}

export default MainHeaderComponent;
