import apiService from "./api.service";
import { Ride } from '../models/ride.model';
import authService from './auth.service';
import { SERVER_LOCATION } from '../utils/config.util';

class RideService {

    async acceptRide(ride: Ride): Promise<any> {
        return apiService.post(SERVER_LOCATION + '/api/ride/' + ride.id + '/accept', {status: 1});
    }

    async acceptRideDriver(ride: Ride): Promise<any> {
        return apiService.post(SERVER_LOCATION + '/api/ride/' + ride.id + '/acceptDriver', {ride_user: {status: 1}, ride: {driver_user_id: authService.user.id}});
    }

    async declineRide(ride: Ride): Promise<any> {
        return apiService.post(SERVER_LOCATION + '/api/ride/' + ride.id + '/decline', {status: 2});
    }

    async markRideAsFinished(ride: Ride): Promise<any> {
        return apiService.post(SERVER_LOCATION + '/api/ride/' + ride.id + '/markAsFinished', {});
    }
}
export default RideService;

