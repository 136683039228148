

class DateService {

    static formatDate(date: Date): string {
        return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    }

    static formatDateForServer(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1) < 10 ? 0 + '' + (date.getMonth() + 1) : (date.getMonth() + 1);
        const day = date.getDate() < 10 ? 0 + '' + date.getDate() : date.getDate();

        const hours = date.getHours() < 10 ? 0 + '' + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? 0 + '' + date.getMinutes() : date.getMinutes();
        const seconds = date.getSeconds() < 10 ? 0 + '' + date.getSeconds() : date.getSeconds();

        return year+ '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
}
export default DateService;

