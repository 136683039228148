import apiService from "./api.service";
import { Route } from '../models/route.model';
import { Ride } from '../models/ride.model';
import { CancelTerm } from '../models/cancel-term.model';
import { ExtraRide } from '../models/ExtraRide.model';
import { SERVER_LOCATION } from '../utils/config.util';
import { Payment } from '../models/payment.model';

class RouteService {

    async getJoinedRoutes(): Promise<Route[]> {
        return apiService.get(SERVER_LOCATION + '/api/routes/joined', {});
    }

    async getUnresolvedRides(routeId: number): Promise<Ride[]> {
        return apiService.get(SERVER_LOCATION + '/api/rides/unresolved/' + routeId, {});
    }

    async getResolvedRides(routeId: number): Promise<Ride[]> {
        return apiService.get(SERVER_LOCATION + '/api/rides/resolved/' + routeId, {});
    }

    async getPayPayments(routeId: number): Promise<Payment[]> {
        return apiService.get(SERVER_LOCATION + '/api/routes/payments/' + routeId + '/toPay', {});
    }

    async getReceivePayments(routeId: number): Promise<Payment[]> {
        return apiService.get(SERVER_LOCATION + '/api/routes/payments/' + routeId + '/toReceive', {});
    }

    async setPaymentReceived(paymentId: number): Promise<any> {
        return apiService.get(SERVER_LOCATION + '/api/routes/payments/' + paymentId + '/setReceived', {});
    }

    async getRoadBudget(routeId: number): Promise<any> {
        return apiService.get(SERVER_LOCATION + '/api/routes/' + routeId + '/budget', {});
    }

    async getMyRoutes(): Promise<Route[]> {
        return apiService.get(SERVER_LOCATION + '/api/routes', {});
    }

    async getMyRoutesCancelTerms(routeId: number): Promise<CancelTerm[]> {
        return apiService.get(SERVER_LOCATION + '/api/routes/' + routeId + '/cancel-terms', {});
    }

    async deleteCancelTerm(cancelTermId: number): Promise<any> {
        return apiService.delete(SERVER_LOCATION + '/api/routes/cancel-terms/' + cancelTermId, {});
    }

    async addNewCancelTerm(cancelTerm: CancelTerm): Promise<any> {
        return apiService.post(SERVER_LOCATION + '/api/routes/cancel-terms', cancelTerm);
    }

    async getMyRoutesExtraRides(routeId: number): Promise<ExtraRide[]> {
        return apiService.get(SERVER_LOCATION + '/api/routes/' + routeId + '/extra-terms', {});
    }

    async deleteExtraTerm(cancelTermId: number): Promise<any> {
        return apiService.delete(SERVER_LOCATION + '/api/routes/extra-term/' + cancelTermId, {});
    }

    async addNewExtraTerm(cancelTerm: CancelTerm): Promise<any> {
        return apiService.post(SERVER_LOCATION + '/api/routes/extra-term', cancelTerm);
    }

    async addNewExtraRide(extraRide: CancelTerm, routeId: number): Promise<any> {
        return apiService.post(SERVER_LOCATION + '/api/routes/' + routeId + '/extra-ride', extraRide);
    }

    async generatePayments() {
        return apiService.get(SERVER_LOCATION + '/api/routes/generate-payments');
    }
}
export default RouteService;

